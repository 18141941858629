import service from '@/utils/request'
 const BASE = 'https://api-test.westlake.ink/'
//  const BASE = 'https://api.westlake.ink/'

export const getSTSToken = userId => {
    const params = { userId }
    // const params = { userId: '36490051239593472' }
    return service({
        url: BASE + 'third-party/api/oss/getSTSTokenAdmin',
        method: 'get',
        params
    })
}